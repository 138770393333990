.h2Title {
    margin: 20px;
    color: #FFFFFF;
    font-size: 200%;
}

.boxListProduct {
    min-height: 100px;
    border: 1px solid #323232;
    margin: 20px;
    border-radius: 10px;
    padding: 15px;
    background-color: #20222c;
}

.boxListProduct2 {
    min-height: 100px;
    border: 1px solid #323232;
    margin: 7px 7px 60px;
    border-radius: 10px;
    padding: 10px 10px 15px;
    background-color: #20222c;
    cursor: pointer;
}

.boxListProduct3 {
    min-height: 100px;
    border: 1px solid #323232;
    margin: 7px 7px 7px;
    border-radius: 10px;
    padding: 10px 10px 15px;
    background-color: #20222c;
}

.boxListProduct4 {
    min-height: 215px;
    border: 1px solid #323232;
    margin: 7px 7px 7px;
    border-radius: 10px;
    padding: 10px 10px 15px;
    background-color: #20222c;
}

.insideBoxListProduct {
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #2a2a2a;
}

.boxNftLogo {
    height: 40px;
    margin: 12px;
}

.nftValue {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 120%;
}

.nftValueConvert {
    color: #999999;
    font-size: 85%;
}

.nftImage {
    width: 100%;
    margin-bottom: -25px;
    margin-top: -90px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.dashboardIconLogo {
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.textWhite90 {
    color: #FFFFFF;
    font-size: 90%;
}

.textGrey80 {
    color: #999999;
    font-size: 80%;
}

.hrGrey {
    padding: 0px;
    margin: 10px;
    color: #323232;
}

.axieClassTag {
    font-size: 80%;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 2px 7px;
}

.spinCenter {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}

.ant-empty-description {
    color: #FFFFFF;
}

.roninwalletEditable {
    color: #FFFFFF;
    cursor: pointer;
}

.whiteCenter {
    color: #FFFFFF;
    margin-top: 20px;
    text-align: center !important;
}

.title1 {
    color: #999999;
    font-size: 120%;
}

.title1-2 {
    color: #fff;
    font-size: 120%;
}

.title2 {
    color: #FFFFFF;
    font-size: 180%;
    margin-top: 7px;
}

.mostUsedAxie {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.battleAxieImage {
    width: 85px;
    cursor: pointer;
}

.textWIN {
    color: #66e57d;
    font-size: 90%;
}

.textLOSE {
    color: #fd4f84;
    font-size: 90%;
}

.textDRAW {
    color: #7a6bd5;
    font-size: 90%;
}

.ant-table{
    border-radius: 10px !important;
    background-color: #21232c;
    color: #FFFFFF;
}

.ant-table-content{
    border-radius: 10px !important;
}

.ant-table-thead{
    border-radius: 10px !important;
    background-color: #21232c !important;
    color: #FFFFFF;
}

.ant-table-cell-row-hover{
    background-color: #585b68 !important;
    color: #FFFFFF;
}

td.ant-table-column-sort {
    background-color: #21232c !important;
}
