@small: ~"(max-width: 699px)";
@medium: ~"(max-width: 1000px) and (min-width: 700px)";
@large: ~"(min-width: 701px) and (max-width: 1151px)";
@xlarge: ~"(min-width: 1151px)";

#sign-up-left-side {
  color: #FFF;
  background-image: url("https://media.discordapp.net/attachments/944037648257929316/944037721456930826/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  #text-left-side-wrapper {
    position: relative;
    top: 120px;
    padding: 0 120px;

    h1 {
      color: #FFF;
      font-size: 420%;
      font-weight: bolder;
    }
  }
}

#sign-up-link {
  float: right;
  padding-top: 20px;
  padding-right: 32px;
}

.header-logo {
  height: 50px;
  margin: 10px;
}

#sign-up-body {
  height: 100vh;
}

#sign-up-wrapper {
  height: 100%;
}

#sign-up-right-side {
  background: #1c1e26;

  #form-wrapper {
    position: relative;
    top: 320px;
    padding: 0 120px;

    color: #FFFFFF;


    form {
      label {
        color: #FFF;
      }

      .ant-input {
        border-radius: 20px;
      }


      .ant-input-password {
        border-radius: 20px;
      }
    }
  }

  h2 {
    color: #FFFFFF;
  }
}

@media @large {
  #sign-up-left-side {

    #text-left-side-wrapper {
      padding: 0 30px;

      h1 {
        font-size: 320%;
      }
    }
  }

  #sign-up-right-side {

    #form-wrapper {
      padding: 0 20px;
    }

    h2 {
      color: #FFFFFF;
    }
  }

}


@media @medium {
  #sign-up-body {
    height: auto;
  }

  #sign-up-left-side {

    #text-left-side-wrapper {
      padding: 20px 20px;
      top: auto;

      h1 {
        text-align: center;
        font-size: 220%;
      }
    }
  }

  #sign-up-right-side {

    min-height: 86vh !important;

    #form-wrapper {
      top: auto;
      padding: 20px 20px;
      min-height: 86vh !important;
    }

    h2 {
      color: #FFFFFF;
    }
  }

}

@media @small {

  #sign-up-body {
    height: auto;
  }

  #sign-up-left-side {
    #text-left-side-wrapper {
      position: relative;
      top: 10px;
      padding: 20px 20px;

      h1 {
        color: #FFF;
        font-size: 1.5em;
        font-weight: bolder;
      }
    }
  }


  #sign-up-right-side {
    background: #1c1e26;
    padding-bottom: 20px;
    min-height: 86vh !important;

    #form-wrapper {
      position: relative;
      top: 20px;
      padding: 20px 20px;
      min-height: 86vh !important;

      color: #FFFFFF;

      form {
        label {
          color: #FFF;
        }

        .ant-input {
          border-radius: 20px;
        }


        .ant-input-password {
          border-radius: 20px;
        }
      }
    }

    h2 {
      color: #FFFFFF;
    }


    #button-signup-third-party {
      .signup-button, .signup-button-white {
        padding: 8px;
      }
    }

  }
}
